import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get, post, remove } from '../../api';
import {
  TChainAddItem,
  TChainByUserResponse,
  TChainListResponse,
  TUseAddChains,
  TUseChainList,
  TUseChains,
} from './ChainService.props';

export const useChain = ({ userId, email, country }: TUseChains) => {
  const queryClient = useQueryClient();

  const paramsByRequest = `chainsByUser?email=${email}&term=`;
  return useQuery<TChainByUserResponse>({
    queryKey: ['chains', userId, country],
    queryFn: ({ signal }) =>
      get({
        url: `api/link-customer-list-service/chain/${paramsByRequest}&user_id=${userId}&page=1&size=1000`,
        config: {
          signal,
          headers: {
            country,
            userId,
          },
        },
      }),
    onError: () => {
      queryClient.setQueryData(['chains', userId, country], {
        chains: [],
      });
    },
    keepPreviousData: false,
  });
};

export const useChainList = ({
  pageNumber,
  pageSize,
  term,
  country,
  userId,
  enabled,
}: TUseChainList) => {
  const queryClient = useQueryClient();

  const customParams = term.length ? `/chainsByTerm?term=${term}&chain_selection=true&` : '?';
  return useQuery<TChainListResponse>({
    queryKey: ['chains-list', userId, pageNumber, pageSize, term],
    queryFn: ({ signal }) =>
      get({
        url: `api/link-customer-list-service/chain${customParams}user_id=${userId}&page=${pageNumber}&size=${pageSize}`,
        config: {
          signal,
          headers: {
            country,
            userId,
          },
        },
      }),
    onError: () => {
      queryClient.setQueryData(['chains-list', userId, pageNumber, pageSize, term], {
        chains: [],
      });
    },
    enabled,
    staleTime: 0,
    keepPreviousData: false,
  });
};

export const useAddChains = ({ userId, country }: TUseAddChains) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['add-chain'],
    mutationFn: (chains: TChainAddItem[]) =>
      post({
        url: `api/link-customer-list-service/chain/chainsByUser/${userId}`,
        body: chains,
        config: {
          headers: {
            country,
            userId,
          },
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['chains']);
    },
  });
};

export const useDeleteChains = ({ chainId, userId, country }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-chain'],
    mutationFn: () =>
      remove({
        url: `api/link-customer-list-service/chain/chainsByUser/${userId}/${chainId}`,
        config: {
          headers: {
            country,
            userId,
          },
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['chains']);
    },
  });
};
