import { PageLoader } from '@components';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { lazy, LazyExoticComponent, ReactElement, Suspense } from 'react';

export type Modules = 'CUSTOMER_LIST' | 'KAM_LIST_PAGE' | 'KAM_CUSTOMER_LIST_PAGE';

type Route = {
  path: string;
  Element: LazyExoticComponent<() => ReactElement>;
  children?: Route[];
};

type RoutesProps = Record<Modules, Route>;

export const userPermissions = {
  read: 'LinkAdmin.CustL.Read',
  write: 'LinkAdmin.CustL.Write',
  all: ['LinkAdmin.CustL.Read', 'LinkAdmin.CustL.Write'],
};

const CustomerListPage = lazy(() => import('pages/CustomerListPage'));
const KAMListPage = lazy(() => import('pages/KAMListPage'));
const KAMCustomerListPage = lazy(() => import('pages/KAMCustomerListPage'));

export const homePath = 'link-admin';
export const customerListPath = '/customer-list';
export const kamListPath = `${customerListPath}/kam`;
export const kamCustomerListPath = `${kamListPath}/customers`;

export const Routes: RoutesProps = {
  CUSTOMER_LIST: { path: customerListPath, Element: CustomerListPage },
  KAM_LIST_PAGE: { path: kamListPath, Element: KAMListPage },
  KAM_CUSTOMER_LIST_PAGE: { path: kamCustomerListPath, Element: KAMCustomerListPage },
};

export const modules = [Routes.CUSTOMER_LIST, Routes.KAM_LIST_PAGE, Routes.KAM_CUSTOMER_LIST_PAGE];

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <ProtectedRoute isEnabled permission={userPermissions.all}>
        {Element ? <Element /> : <></>}
      </ProtectedRoute>
    </Suspense>
  ),
}));
