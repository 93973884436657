export const enUS = {
  breadcrumb: {
    informationCenter: {
      title: 'Information center',
    },
    customerList: {
      title: 'Customer list',
    },
    chainManagement: {
      title: 'Chain management',
    },
    kamList: {
      title: "KAM's customer list",
    },
    kamCustomerList: {
      title: "KAM's customer list",
    },
  },
  pages: {
    customerList: {
      title: 'Which list would you like to manage?',
      featureCards: {
        kam: {
          title: 'KAM',
          description: "Change KAM's customer catalog",
        },
      },
    },
    kamList: {
      title: "KAM's Customer list",
      subtitle: "Manage KAM's customers list",
      selectKAM: 'Select a KAM',
      searchPlaceholder: 'Search users by name or e-mail',
      employeeEmail: 'Employee email',
      oneResultFound: ' result found',
      resultsFound: ' results found',
      loadingTitle: 'Loading',
      loadingText: "We're almost there...",
    },
    kamCustomerList: {
      title: "KAM's customers list",
      employeeEmail: 'Employee email',
      employeeIdDescription: 'Employee ID',
      chainTable: {
        title: "{name}'s customer catalog",
        chainName: 'Chain name',
        rowsPerPage: 'Items per page',
        paginationCounter: 'of',
        loadingMessage: 'Loading chains...',
        emptyMessage: 'No chain added...',
        btnAddChain: 'Add chain',
        searchPlaceHolder: 'Search by name',
      },
      chainAddChainList: {
        title: 'Chain selection',
        table: {
          name: 'Name',
          id: 'ID',
          rowsPerPage: 'Items per page',
          paginationCounter: 'of',
          loadingMessage: 'Loading chains...',
          emptyMessage: 'Chain not found',
          searchPlaceHolder: 'Search by name',
          buttonAdd: 'Add',
        },
        btnContinue: 'Confirm',
        btnCancel: 'Cancel',
      },
      chainDeleteChain: {
        title: "Are you sure you want to remove this chain from {name}'s list?",
        nameToRemove: 'Name: {name}',
        btnContinue: 'Remove',
        btnCancel: 'Cancel',
      },
    },
  },
  errorMessage: {
    accessDenied: {
      title: 'Hmm... Access denied!',
      subtitle: "It looks like you don't have permission to access this page.",
      buttonText: 'Home',
    },
  },
};

export default enUS;
