import { enUS } from './en-US';

export const ptBr: typeof enUS = {
  breadcrumb: {
    informationCenter: {
      title: 'Central de informação',
    },
    customerList: {
      title: 'Lista de clientes',
    },
    chainManagement: {
      title: 'Gerenciamento de redes',
    },
    kamList: {
      title: 'Lista de clientes de KAMs',
    },
    kamCustomerList: {
      title: 'Lista de clientes do KAM',
    },
  },
  pages: {
    customerList: {
      title: 'Qual lista você gostaria de gerenciar?',
      featureCards: {
        kam: {
          title: 'KAM',
          description: 'Alterar lista de clientes de KAMs',
        },
      },
    },
    kamList: {
      title: 'Lista de clientes de KAMs',
      subtitle: 'Gerenciar lista de clientes KAMs',
      selectKAM: 'Selecionar KAM',
      searchPlaceholder: 'Encontre KAM por nome ou email',
      employeeEmail: 'Email',
      oneResultFound: ' resultado encontrado',
      resultsFound: ' resultados encontrados',
      loadingTitle: 'Loading',
      loadingText: "We're almost there...",
    },
    kamCustomerList: {
      title: 'Lista de clientes do KAM',
      employeeEmail: 'Email',
      employeeIdDescription: 'ID do Empregado',
      chainTable: {
        title: 'Catálogo de clientes do {name}',
        chainName: 'Nome da rede',
        rowsPerPage: 'Registros por pagina',
        paginationCounter: 'de',
        loadingMessage: 'Carregando redes...',
        emptyMessage: 'Nenhuma rede adicionada',
        btnAddChain: 'Adicionar rede',
        searchPlaceHolder: 'Buscar por nome',
      },
      chainAddChainList: {
        title: 'Selecionar rede',
        table: {
          name: 'Nome da rede',
          id: 'Identificação',
          rowsPerPage: 'Registros por pagina',
          paginationCounter: 'de',
          loadingMessage: 'Carregando redes...',
          emptyMessage: 'Rede não encontrada',
          searchPlaceHolder: 'Buscar por nome',
          buttonAdd: 'Adicionar',
        },
        btnContinue: 'Confirmar',
        btnCancel: 'Cancelar',
      },
      chainDeleteChain: {
        title: 'Tem certeza de que deseja remover esta rede da lista de {name}?',
        nameToRemove: 'Nome: {name}',
        btnContinue: 'Remover',
        btnCancel: 'Cancelar',
      },
    },
  },
  errorMessage: {
    accessDenied: {
      title: 'Hmm... Acesso negado!',
      subtitle: 'Parece que essa página não foi encontrada ou não existe.',
      buttonText: 'Home',
    },
  },
};

export default ptBr;
