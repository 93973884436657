import { Grid } from '@hexa-ui/components';
import { useDynamicSidebar } from '@hooks';
import { getAppHeaderConfig } from '@utils';
import { useAppHeader } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as Styles from './PageWrapper.styles';
import { PageWrapperProps } from './PageWrapper.types';

export const PageWrapper = ({ children, pageName, breadcrumbItems }: PageWrapperProps) => {
  const [, setAppHeaderConfig] = useAppHeader();
  const { formatMessage } = useIntl();

  useDynamicSidebar();

  useEffect(() => {
    const setBreadcrumb = async () => {
      let appHeaderConfig = await getAppHeaderConfig(pageName, formatMessage);

      if (breadcrumbItems) {
        appHeaderConfig = {
          ...appHeaderConfig,
          breadcrumbConfig: {
            ...appHeaderConfig.breadcrumbConfig,
            items: [...appHeaderConfig.breadcrumbConfig.items, ...breadcrumbItems],
          },
        };
      }

      /* istanbul ignore next */
      setBreadcrumb().catch((error) => console.error(error));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, setAppHeaderConfig]);

  return (
    <Grid.Container sidebar type="fluid">
      <Styles.Content>{children}</Styles.Content>
    </Grid.Container>
  );
};
